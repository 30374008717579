import {HStack, Icon, Text, ThemeIconKey} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isNotNaN} from 'ramda-adjunct';

import {buildArray, parseDate} from 'shared';

import {SimilarCar} from '../../../../../api/types/carvagoTypes';

interface CarParametersProps {
  car: SimilarCar;
}

export function CarParameters(props: CarParametersProps) {
  const formatNumber = useFormatNumber();
  const getFeatureLabel = (constKey: string) =>
    props.car.catalog_features?.find((feature) => feature.const_key.includes(constKey))?.label ??
    '?';

  const power = isNotNil(props.car.power) ? `${props.car.power} ${props.car.power_unit}` : '?';
  const drive = getFeatureLabel('DRIVE_');
  const transmission = getFeatureLabel('TRANSMISSION_');
  const fuelType = getFeatureLabel('FUELTYPE_');
  const carStyle = getFeatureLabel('CARSTYLE_');
  const mileage = isNotNil(props.car.mileage) ? `${formatNumber(props.car.mileage)} km` : '?';
  const registrationDate = parseDate(props.car.registration_date);
  const registrationYear =
    isNotNil(registrationDate) && isNotNaN(registrationDate) ? registrationDate.getFullYear() : '?';

  const formattedFeatures = buildArray<{icon: ThemeIconKey; text: string | number}>()
    .add({icon: 'automotive/engine', text: power})
    .add({icon: 'automotive/differential', text: drive})
    .add({icon: 'automotive/transmission', text: transmission})
    .add({icon: 'automotive/fuel_type', text: fuelType})
    .add({icon: 'automotive/car', text: carStyle})
    .add({icon: 'automotive/mileage', text: mileage})
    .add({icon: 'automotive/calendar', text: registrationYear});

  return (
    <HStack spacing={4} wrap>
      {formattedFeatures.map((feature) => (
        <HStack key={feature.icon} spacing={2}>
          <Icon value={feature.icon} size={4} />
          <Text size="xSmall" color="secondary" alternative>
            {feature.text}
          </Text>
        </HStack>
      ))}
    </HStack>
  );
}
