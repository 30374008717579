import {Tooltip} from 'platform/components';
import {Box, HStack, Show, useWindowDimensions} from 'platform/foundation';

import {useMemo, useRef, useState} from 'react';

import {suffixTestId, TestIdProps, useToggle} from 'shared';

import {CatalogFeature} from '../../../../../api/types/carvagoTypes';
import {Button} from '../../../../../components/Button/Button';
import {Flag} from '../../../../../components/Flag/Flag';
import {useCatalogue} from '../../../../../hooks/useCatalogue';
import i18n from '../../../../../i18n/i18n';
import {getFeaturesVisible} from '../utils/getFeaturesVisible';

const SHOWN_ROWS = 2;
const SPACING = 8;
const EXPAND_BUTTON_WIDTH = 49;

interface FeatureFlagsProps extends TestIdProps {
  features: CatalogFeature[];
  onToggle?: (isExpanded: boolean) => void;
}

export function FeatureFlags(props: FeatureFlagsProps) {
  const features = useMemo(
    () => props.features.filter((feature) => feature.const_key.includes('FEATURE_')),
    [props.features]
  );
  const [isExpanded, toggleExpanded] = useToggle(false);
  const [featuresVisibleCount, setFeaturesVisibleCount] = useState(features.length);
  const lastVisibleElementRef = useRef<HTMLDivElement>(null);
  const lastVisibleElementIndexRef = useRef<number>(null);
  const {width: windowWidth} = useWindowDimensions();
  const getLabel = useCatalogue();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleExpanded();
    props.onToggle?.(!isExpanded);
  };

  const calculateVisibleElement = (ref: HTMLDivElement | null, index: number) => {
    if (ref) {
      requestAnimationFrame(() => {
        const rect = ref.getBoundingClientRect();
        const parentRect = ref.parentElement?.getBoundingClientRect();

        const row = Math.floor((rect.top - (parentRect?.top ?? 0)) / rect.height) + 1;
        const isRowLastVisible = row === SHOWN_ROWS;
        const isRowFirstNonVisible = row === SHOWN_ROWS + 1;
        const isElementLastAndStillVisible = index === features.length - 1 && row <= SHOWN_ROWS;

        if (isRowLastVisible) {
          lastVisibleElementRef.current = ref;
          lastVisibleElementIndexRef.current = index;
        }

        if (isRowFirstNonVisible) {
          const lastIndex = lastVisibleElementIndexRef.current ?? 0;
          const lastRect = lastVisibleElementRef.current?.getBoundingClientRect();

          setFeaturesVisibleCount(
            getFeaturesVisible(EXPAND_BUTTON_WIDTH + SPACING, lastIndex, lastRect, parentRect)
          );
        }

        if (isElementLastAndStillVisible) {
          setFeaturesVisibleCount(
            isRowLastVisible
              ? getFeaturesVisible(EXPAND_BUTTON_WIDTH + SPACING, index, rect, parentRect)
              : index + 1
          );
        }
      });
    }
  };

  const visibleFeatures = isExpanded ? features : features.slice(0, featuresVisibleCount);
  const hiddenItemsCount = features.length - featuresVisibleCount;
  const hasMoreItems = hiddenItemsCount > 0;

  return (
    <div>
      <Box height={0} overflow="hidden" key={`calculator-${windowWidth}`}>
        <HStack spacing={2} wrap>
          {features.map((feature, index) => (
            <div key={feature.const_key} ref={(ref) => calculateVisibleElement(ref, index)}>
              <Flag
                label={getLabel('feature', feature.const_key, {isShort: true}) ?? ''}
                paddingSize="small"
                isTextAlternative={false}
              />
            </div>
          ))}
        </HStack>
      </Box>
      <HStack spacing={2} wrap>
        {visibleFeatures.map((feature) => (
          <Tooltip key={feature.const_key} label={feature.label}>
            <Flag
              label={getLabel('feature', feature.const_key, {isShort: true}) ?? ''}
              severity="light"
              paddingSize="small"
              isTextAlternative={false}
            />
          </Tooltip>
        ))}

        <Show when={!isExpanded && hasMoreItems}>
          <Button
            title={`${hiddenItemsCount} +`}
            aria-label={`Show ${hiddenItemsCount} more features`}
            size="xSmall"
            variant="black"
            onClick={handleButtonClick}
            data-testid={suffixTestId('expandButton', props)}
          />
        </Show>

        <Show when={isExpanded && hasMoreItems}>
          <Button
            title={i18n.t('general.actions.hide')}
            size="xSmall"
            variant="black"
            onClick={handleButtonClick}
            data-testid={suffixTestId('collapseButton', props)}
          />
        </Show>
      </HStack>
    </div>
  );
}
