import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';

import {carvagoApi} from '../api/carvagoApi';
import {catalogueApi} from '../api/catalogueApi';
import {omneticToolsApi} from '../api/omneticToolsApi';
import {vinDecoderApi} from '../api/vinDecoderApi';

export const store = configureStore({
  reducer: {
    [catalogueApi.reducerPath]: catalogueApi.reducer,
    [vinDecoderApi.reducerPath]: vinDecoderApi.reducer,
    [carvagoApi.reducerPath]: carvagoApi.reducer,
    [omneticToolsApi.reducerPath]: omneticToolsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      catalogueApi.middleware,
      vinDecoderApi.middleware,
      carvagoApi.middleware,
      omneticToolsApi.middleware
    ),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
