import {
  getColorByPath,
  HStack,
  Icon,
  Show,
  Text,
  ThemeColorPath,
  ThemeColorTextPath,
  ThemeIconKey,
} from 'platform/foundation';
import styled, {useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {TestIdProps, suffixTestId} from 'shared';

export type FlagSeverity = 'default' | 'neutral' | 'black' | 'light' | 'success' | 'error' | 'info';
export type FlagPaddingSize = 'xSmall' | 'small' | 'default';

export interface FlagProps extends TestIdProps {
  label?: string;
  icon?: ThemeIconKey;
  severity?: FlagSeverity;
  paddingSize?: FlagPaddingSize;
  isTextAlternative?: boolean;
}

export function Flag(props: FlagProps) {
  const theme = useTheme();
  const hasText = isNotNilOrEmpty(props.label);
  const textColor = match<FlagSeverity | undefined, ThemeColorTextPath>(props.severity)
    .with('info', () => 'darkBlue')
    .with('light', () => 'primary')
    .otherwise(() => 'white');
  const backgroundColor = match<FlagSeverity | undefined, ThemeColorPath>(props.severity)
    .with('neutral', () => 'accent.grey')
    .with('black', () => 'general.black')
    .with('light', () => 'accent.tertiary')
    .with('success', () => 'severity.success')
    .with('error', () => 'severity.error')
    .with('info', () => 'general.lightBlue')
    .otherwise(() => 'accent.primary');
  const paddingVertical = match<FlagPaddingSize | undefined>(props.paddingSize)
    .with('xSmall', () => '1px')
    .with('small', () => '2px')
    .otherwise(() => theme.getSize(1));
  const paddingHorizontal = match<FlagPaddingSize | undefined>(props.paddingSize)
    .with('small', () => theme.getSize(2))
    .otherwise(() => theme.getSize(1));

  return (
    <FlagWrapper
      $paddingVertical={paddingVertical}
      $paddingHorizontal={paddingHorizontal}
      $backgroundColor={backgroundColor}
      data-testid={suffixTestId('flag', props)}
    >
      <HStack align="center">
        <Icon value={props.icon} size={4} color="text.white" />
        <Show when={hasText}>
          <Text size="small" color={textColor} alternative={props.isTextAlternative ?? true}>
            {props.label}
          </Text>
        </Show>
      </HStack>
    </FlagWrapper>
  );
}

const FlagWrapper = styled.div<{
  $paddingVertical: string;
  $paddingHorizontal: string;
  $backgroundColor: ThemeColorPath;
}>`
  padding: ${({$paddingVertical, $paddingHorizontal}) =>
    `${$paddingVertical} ${$paddingHorizontal}`};
  border-radius: ${({theme}) => theme.radii.xSmall};
  background-color: ${({theme, $backgroundColor}) => getColorByPath($backgroundColor, theme)};
`;
