import {APPLICATION_NAME} from '@vin-decoder/config';
import {showNotification} from 'platform/components';
import {Box, Hide, HStack, Show, useElementSize, VStack} from 'platform/foundation';

import {useParams} from 'react-router-dom';

import {RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {A4_WIDTH} from '../../consts/a4Width';
import {PAGE_MAX_WIDTH} from '../../consts/pageMaxWidth';
import {useGetVinDecoderData} from '../../hooks/useGetVinDecoderData';
import i18n from '../../i18n/i18n';
import {featureFlags} from '../../utils/featureFlags';
import {CebiaBanner} from './(sections)/Banners/CebiaBanner';
import {PriceReportBanner} from './(sections)/Banners/PriceReportBanner';
import {Features} from './(sections)/Features/Features';
import {PhotoGallery} from './(sections)/Photogallery/PhotoGallery';
import {SeriesVehicleEquipment} from './(sections)/SeriesVehicleEquipment/SeriesVehicleEquipment';
import {SimilarCars} from './(sections)/SimilarCars/SimilarCars';
import {SpecialVehicleEquipment} from './(sections)/SpecialVehicleEquipment/SpecialVehicleEquipment';
import {VehicleInfo} from './(sections)/VehicleInfo/VehicleInfo';
import {DecodedAlert} from './components/DecodedAlert/DecodedAlert';
import {MobileMenu} from './components/MobileMenu/MobileMenu';
import {Navigation} from './components/Navigation/Navigation';
import {TranslationPendingBar} from './components/TranslationPendingBar/TranslationPendingBar';
import {VinDecoderHeader} from './components/VinDecoderHeader/VinDecoderHeader';

export function MainPage(props: RequiredTestIdProps) {
  const {decoder, make, modelFamily} = useGetVinDecoderData();
  const [isMobileMenuOpen, openMobileMenu, closeMobileMenu] = useBoolean(false);
  const [translationPendingBarRef, , translationPendingBarHeight] =
    useElementSize<HTMLDivElement>();
  const isTranslationPending = decoder?.translationStatus === 'PENDING';
  const isPrintForced = useParams<{param?: string}>().param === 'pdf';

  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#browser_compatibility
  // Some browsers don't support navigator.share yet – in such case we want to copy the URL to clipboard
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `${make} ${modelFamily} • ${APPLICATION_NAME}`,
          url: document.URL,
        });
      } catch (err: unknown) {
        // Cancelling share action throws an AbortError – we want to ignore that one
        if (!(err instanceof Error && err.toString().includes('AbortError'))) {
          showNotification.error(i18n.t('general.notifications.shareError'));
        }
      }
    } else {
      await navigator.clipboard.writeText(document.URL);
      showNotification.info(i18n.t('general.notifications.copiedToClipboard'));
    }

    if (isMobileMenuOpen) {
      closeMobileMenu();
    }
  };

  return (
    <>
      <Hide inPrinter when={!isTranslationPending || isPrintForced}>
        <TranslationPendingBar ref={translationPendingBarRef} />
      </Hide>
      <VinDecoderHeader
        onMenuClick={openMobileMenu}
        onShareClick={handleShare}
        data-testid={suffixTestId('header', props)}
      />
      <Box>
        <VStack align="center">
          <Box
            width={isPrintForced ? A4_WIDTH : '100%'}
            maxWidth={PAGE_MAX_WIDTH}
            paddingHorizontal={isPrintForced ? 10 : [5, 10, 10, 25, 10]}
            paddingVertical={10}
          >
            <VStack spacing={10}>
              <HStack spacing={10}>
                <Box flex={1}>
                  <VStack spacing={6}>
                    <DecodedAlert data-testid={suffixTestId('decodedAlert', props)} />
                    <Features data-testid={suffixTestId('features', props)} />
                    <PhotoGallery data-testid={suffixTestId('photoGallery', props)} />
                    <SpecialVehicleEquipment
                      data-testid={suffixTestId('specialEquipment', props)}
                    />
                    <SeriesVehicleEquipment data-testid={suffixTestId('seriesEquipment', props)} />
                    <VehicleInfo data-testid={suffixTestId('vehicleInfo', props)} />
                    <Hide inPrinter when={isPrintForced}>
                      <Show whenFeatureEnabled={featureFlags.V1N_PRICE_REPORT_BANNER}>
                        <PriceReportBanner data-testid={suffixTestId('priceReportBanner', props)} />
                      </Show>
                      <Show whenFeatureEnabled={featureFlags.V1N_CEBIA_BANNER}>
                        <CebiaBanner data-testid={suffixTestId('cebiaBanner', props)} />
                      </Show>
                    </Hide>
                  </VStack>
                </Box>
                <Hide onMobile inPrinter when={isPrintForced}>
                  <Box width={60}>
                    <Navigation
                      offset={isTranslationPending ? translationPendingBarHeight : 0}
                      data-testid={suffixTestId('navigation', props)}
                    />
                  </Box>
                </Hide>
              </HStack>
              <SimilarCars data-testid={suffixTestId('similarCars', props)} />
            </VStack>
          </Box>
        </VStack>
      </Box>
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onCloseClick={closeMobileMenu}
        onShareClick={handleShare}
        data-testid={suffixTestId('mobileMenu', props)}
      />
    </>
  );
}
