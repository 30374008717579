import {environment} from '@vin-decoder/environment';

import {isNotEmpty, isNil} from 'ramda';

import {buildArray} from 'shared';

import {V1NGetDecoderApiResponse} from '../../../../../api/types/vinDecoderTypes';
import {POWER_RANGE} from '../consts/POWER_RANGE';
import {YEAR_RANGE} from '../consts/YEAR_RANGE';

export const getCarvagoUrl = (decoder: V1NGetDecoderApiResponse) => {
  const year = decoder?.data?.firstRegistrationOnYear ?? decoder?.data?.manufacturedOnYear;
  const power = decoder?.data?.power;

  const parameters = buildArray()
    .whenNot(isNil(decoder?.data?.modelFamily), `model=${decoder?.data?.modelFamily}`)
    .whenNot(
      isNil(year),
      `registration-date-from=${Number(year) - YEAR_RANGE}&registration-date-to=${Number(year) + YEAR_RANGE}`
    )
    .whenNot(isNil(decoder?.data?.fuelType), `fuel-type=${decoder?.data?.fuelType}`)
    .whenNot(
      isNil(power),
      `power-from=${Math.max(0, Number(power) - POWER_RANGE)}&power-to=${Number(power) + POWER_RANGE}&power-unit=kw`
    )
    .whenNot(isNil(decoder?.data?.transmission), `transmission=${decoder?.data?.transmission}`);

  const link = isNotEmpty(parameters)
    ? `${environment.CARVAGO_URL}cars?${parameters.join('&')}`
    : environment.CARVAGO_URL;

  return link;
};
