import {fetchBaseQuery} from '@reduxjs/toolkit/query';
import {createApi} from '@reduxjs/toolkit/query/react';
import {environment} from '@vin-decoder/environment';

import {ListedCarsRequestArgs, ListedCarsResponse} from './types/carvagoTypes';

export const carvagoApi = createApi({
  reducerPath: 'carvago',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.CARVAGO_API_URL,
  }),
  endpoints: (build) => ({
    getListedCars: build.query<ListedCarsResponse, ListedCarsRequestArgs>({
      query: (queryArg) => ({
        url: '/listedcars',
        method: 'POST',
        body: queryArg.requestBody,
        headers: {
          'X-Currency': queryArg.currency,
          'X-user-account-company-country': queryArg.country,
          'X-Locale': queryArg.locale,
          'X-Market': queryArg.market,
        },
      }),
    }),
  }),
});

export const {useGetListedCarsQuery} = carvagoApi;
