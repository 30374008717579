import {isNotNil} from 'ramda';

import {useGetVinDecoderData} from './useGetVinDecoderData';

export const useCatalogue = () => {
  const {catalogue} = useGetVinDecoderData();

  return (
    name?: string,
    value?: string | null,
    options: {fallback?: 'key' | 'null'; isShort?: boolean} = {fallback: 'key', isShort: false}
  ) => {
    if (isNotNil(catalogue) && isNotNil(name) && isNotNil(value)) {
      const propertyEnum = catalogue.find((item) => item.property_name === name)?.keys;
      const labels = propertyEnum?.find((item) => item.const_key === value)?.labels;
      const label = options.isShort ? labels?.[0]?.label_short : labels?.[0]?.label;
      if (isNotNil(label)) {
        return label;
      }
    }
    return options.fallback === 'key' ? value : null;
  };
};
