import {V1NGetDecoderApiResponse} from '../../../../../api/types/vinDecoderTypes';
import {POWER_RANGE} from '../consts/POWER_RANGE';
import {YEAR_RANGE} from '../consts/YEAR_RANGE';

export const getFilters = (decoder: V1NGetDecoderApiResponse) => {
  const year = decoder?.data?.firstRegistrationOnYear ?? decoder?.data?.manufacturedOnYear;
  const power = decoder?.data?.power;
  const relaxedFilters = {
    model: decoder?.data?.modelFamily ? [decoder.data.modelFamily] : undefined,
    'registration-date-from': year ? Number(year) - YEAR_RANGE : undefined,
    'registration-date-to': year ? Number(year) + YEAR_RANGE : undefined,
    sort: 'publish-date',
    limit: 3,
  };
  const strictFilters = {
    ...relaxedFilters,
    transmission: decoder?.data?.transmission ? [decoder.data.transmission] : undefined,
    'fuel-type': decoder?.data?.fuelType ? [decoder.data.fuelType] : undefined,
    'power-from': power ? Math.max(0, Number(power) - POWER_RANGE) : undefined,
    'power-to': power ? Number(power) + POWER_RANGE : undefined,
    'power-unit': 'kw' as const,
  };
  return {relaxedFilters, strictFilters};
};
